import React from 'react'
import { Row, Col,FormGroup, Label,} from "reactstrap";
 import Select from "react-select";
 import SelectFetch from "./SelectFetch";
 
const ModuleSelect = ({
  props,
  handleInputChange,
  getOptions,
  eachEntry,
  errors,
  actions,
  optionsSubjects,
  optionsPeriods,
  student,
  courseUrl,
}) => {
  const { course_id, subject_id, period_id, student_id } = eachEntry;
console.log(courseUrl)
  return (
    <Row>
      <Col lg="3">
        <FormGroup>
          <Label>{props.t("Courses")}</Label>
          <SelectFetch
          
             url={"courses/select/all"}
            minMenuHeight={600}
            value={course_id.value}
            onChange={(selected) => {
              handleInputChange("course_id", selected);
              getOptions(`courses/selects/${selected.value}`);
            }}
            placeholder={props.t("Select a Course")}
          />
          <small className="text-danger">{errors?.course_id}</small>
        </FormGroup>
      </Col>
      {student && (
        <Col lg="3">
          <FormGroup>
            <Label>{props.t("Students")}</Label>
            <SelectFetch
              url={`/courses/${course_id.value.value}/select/students`}
              // minMenuHeight={600}
              value={student_id.value}
              onChange={(selected) => {
                handleInputChange("student_id", selected);
                // getOptions(`courses/selects/${selected.value}`);
              }}
              placeholder={props.t("Select a Student")}
            />
            <small className="text-danger">{errors?.student_id}</small>
          </FormGroup>
        </Col>
      )}
      <Col lg="3">
        <FormGroup className="select2-container">
          <Label>{props.t("Subjects")}</Label>
          <Select
            menuPortalTarget={document.body}
            value={subject_id.value}
            onChange={(selected) => handleInputChange("subject_id", selected)}
            options={optionsSubjects}
            placeholder={props.t("First select a course")}
          />
          <small className="text-danger">{errors?.subject_id}</small>
        </FormGroup>
      </Col>
      <Col lg="3">
        <FormGroup className="select2-container">
          <Label>{props.t("Periods")}</Label>
          <Select
            menuPortalTarget={document.body}
            value={period_id.value}
            onChange={(selected) => handleInputChange("period_id", selected)}
            options={optionsPeriods}
            placeholder={props.t("Select a Period")}
          />
          <small className="text-danger">{errors?.period_id}</small>
        </FormGroup>
      </Col>
      <Col lg="3">
        <FormGroup>{actions}</FormGroup>
      </Col>
    </Row>
  );
};

export default ModuleSelect
