import React, { useReducer } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { mainApi } from "../../../services/api";
import { reducer, initialState } from "./reducer";
import SelectFetch from "../../../components/Common/SelectFetch";
import PrimariaBulletin from "../Bulletin/Primaria/PrimariaBulletin";
import SecundariaBulletin from "../Bulletin/Secondaria/SecundariaBulletin";
import Select from "react-select";
import { handleValidationBeforeSubmit } from "../../../helpers/validator";
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { criteria } from "../Bulletin/Primaria/Criteria";

const Bulletin = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    eachEntry,
    optionsPeriods,
    isLoading,
    errors,
    studentData,
    typeOfBulletin,
    literal,
  } = state;
  const { course_id, period_id, search } = eachEntry;
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleInputChange = (key, value) => {
    if (errors?.[key]) {
      dispatch({ type: "RESET_AN_ENTRY_ERROR", payload: [key] });
    }
    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        ...eachEntry,
        [key]: { ...eachEntry?.[key], value: value },
      },
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const getOptions = (url) => {
    dispatch({
      type: "RESET_OPTIONS",
    });

    mainApi.get(url).then((response) => {
      if (response.data.success) {
        dispatch({
          type: "SET_DEPENDABLE_OPTIONS",
          payload: response.data,
        });
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const getBulletinReport = () => {
    const checkErrors = handleValidationBeforeSubmit(eachEntry);
    if (JSON.stringify(checkErrors) !== "{}") {
      dispatch({ type: "SET_ERRORS", payload: checkErrors });
    } else {
      if (isLoading) {
        return;
      }
      dispatch({ type: "LOADING" });
      mainApi
        .get(
          `/reports/bulletin/course/${course_id.value.value}/periodo/${period_id.value.value}`
        )
        .then((response) => {
          dispatch({
            type: "LOAD_ALL_STUDENT",
            payload: response.data,
          });
        });
    }
  };
  const applyCriteria = () => {
    if (literal) {
      dispatch({ type: "NUMERIC_ENABLED" });
      
    }else{
      dispatch({ type: "LITERAL_ENABLED" });
    }
   
    

    // let changeRows = studentData.map((item) => {
    //   item.p_1 = item.p_1.map((row) => {
    //     let obj = {
    //       subject_name: row.subject_name,
    //       final_grade: criteria(row.final_grade),
    //     };
      
    //     return obj;
    //   });
    //   return item;
    // });

    // dispatch({
    //   type: "APPLY_CRITERIA",
    //   payload: changeRows,
    // });
  };

  function printInvoice() {
    window.print();
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Reports")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <CardTitle className="d-print-none">
                  {props.t("Bulletin")}
                </CardTitle>
                <CardSubtitle className="mb-3 d-print-none">
                  {props.t("Fill all information below")}
                </CardSubtitle>
                <Row className="d-print-none">
                  <Col lg="3">
                    <FormGroup>
                      <Label>{props.t("Courses")}</Label>
                      <SelectFetch
                        url={"courses/select/all"}
                        minMenuHeight={600}
                        value={course_id.value}
                        onChange={(selected) => {
                          handleInputChange("course_id", selected);
                          getOptions(`courses/selects/${selected.value}`);
                        }}
                        placeholder={props.t("Select a Course")}
                      />
                      <small className="text-danger">{errors?.course_id}</small>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup className="select2-container">
                      <Label>{props.t("Periods")}</Label>
                      <Select
                        menuPortalTarget={document.body}
                        value={period_id.value}
                        onChange={(selected) =>
                          handleInputChange("period_id", selected)
                        }
                        options={optionsPeriods}
                        placeholder={props.t("Select a Period")}
                      />
                      <small className="text-danger">{errors?.period_id}</small>
                    </FormGroup>
                  </Col>

                  <Col lg="3">
                    <FormGroup>
                      <Button
                        onClick={getBulletinReport}
                        type="submit"
                        color="primary"
                        className=" mt-4 waves-effect waves-light"
                        disabled={isLoading}
                      >
                        {props.t("Find")}
                      </Button>
                      {studentData && (
                        <Button
                          onClick={() => {
                            printInvoice();
                          }}
                          color="success"
                          className=" mt-4 ml-2 waves-effect waves-light"
                          disabled={isLoading}
                        >
                          <i className="fa fa-print"></i>
                        </Button>
                      )}
                      {typeOfBulletin === "Primaria" && (
                        <Button
                          onClick={() => {
                            applyCriteria();
                          }}
                          color= {literal?"info":"warning"}
                          className=" mt-4 ml-2 waves-effect waves-light"
                         
                        >
                          {literal ? props.t("Numeric"):props.t("Literal") }

                        </Button>
                      )}
                    </FormGroup>
                  </Col>
                  {studentData && (
                    <Col lg="3">
                      <FormGroup className="select2-container">
                        <Label>{props.t("Filter Students")}</Label>
                        <Input
                          placeholder={props.t("Search students...")}
                          value={search.value}
                          onChange={(e) => {
                            handleInputChange("search", e.target.value);
                          }}
                        />
                        <small className="text-danger">{errors?.search}</small>
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                {isLoading && (
                  <div>
                    Sincronizando los datos
                    <Spinner type="grow" color="info" />
                  </div>
                )}
                {typeOfBulletin && (
                  <>
                    {typeOfBulletin === "Inicial" && (
                      <div className="text-warning">
                        No existen datos para el nivel inicial
                      </div>
                    )}
                    {typeOfBulletin === "Primaria" &&
                      studentData
                        .filter((x) => {
                          return (
                            x.user_name
                              .toLowerCase()
                              .indexOf(search.value.toLowerCase()) !== -1
                          );
                        })
                        .map((t, k) => {
                      

                          return (
                            <PrimariaBulletin
                            literal={literal}
                              criteria={criteria}
                              key={k}
                              data={t}
                            />
                          );
                        })}

                    {typeOfBulletin === "Secundaria" &&
                      studentData
                        .filter((x) => {
                          return (
                            x.user_name
                              .toLowerCase()
                              .indexOf(search.value.toLowerCase()) !== -1
                          );
                        })
                        .map((t, k) => {
                          return (
                            <div key={k} className="mt-0">
                              <SecundariaBulletin data={t} key={k} />
                            </div>
                          );
                        })}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(Bulletin);