import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import StudentList from "../pages/Students/All Students/";
import StudentAdd from "../pages/Students/Admission Form";
import StudentPromotion from "../pages/Students/Student Promotion";

import GradeAdd from "../pages/Grades/Add";
import GradeAddConduct from "../pages/Grades/Add Conduct";

import ReportsBulletin from "../pages/Reports/Bulletin";
import ReportsCompetence from '../pages/Reports/Competence'
import ReportStudent from "../pages/Reports/Student";
import Monitor from "../pages/Monitor/Monitor";
import AutoLogin from "../pages/Authentication/AutoLogin";
import ExhortationNote from "../pages/BehaviorTrack/ExhortationNote";
const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/grade/conduct", component: GradeAddConduct },

  { path: "/students/list", component: StudentList },
  { path: "/students/add", component: StudentAdd },
  { path: "/students/promotion", component: StudentPromotion },
  { path: "/grade/add", component: GradeAdd },
  { path: "/reports/bulletin", component: ReportsBulletin },
  { path: "/reports/student/:mkey", component: ReportStudent },
  { path: "/reports/competence", component: ReportsCompetence },
  { path: "/grade/conduct", component: GradeAddConduct },
  { path: "/monitor", component: Monitor },
  { path: "/exhortation-notes/:mkey", component:  ExhortationNote},  
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auto-login", component: AutoLogin },
];

export { userRoutes, authRoutes };
