
import { useReducer, useEffect } from "react";
import useFetch from "./useFetch";
import Toast from "../helpers/toastr";
import { handleTable } from "../helpers/handleTables";
import { handleValidation } from "../helpers/validator";
import Axios from "axios";
import { mainApi } from "../services/api";

const APIURL = process.env.REACT_APP_API_URL;





export const useCrud = ({ reducer, initialState, WorkWith, validate }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const { isMode, eachEntry, isSubmit, id,errors } = state;
  const { singular, plural, pluralM } = WorkWith;
  const { response, isLoading, isError } = useFetch({
    api: mainApi,
    method: "get",
    url: `/${plural}`,
    //config: JSON.stringify({ requireAuthentication: true }),
  });
//const { errors, valid, handleValidation, handleResetError } = useValidator();
  
  /*____________________________________________________________________________________________________________________________________________________ */
  const toggle = () => {
    dispatch({ type: "INITIAL_STATE" });
  };
  const toggleAdd = () => {
    dispatch({ type: "CREATE_START" });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const getrow = (row, option) => {
    if (option === "update") {
      dispatch({ type: "UPDATE_START", payload: row });
    } else if (option === "delete") {
      dispatch({ type: "DELETE_START", payload: row });
    }
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleInputChange = (e) => {
     
    if (errors?.[e.target.name]) {
       
        dispatch({ type: "RESET_AN_ENTRY_ERROR", payload: [e.target.name] });
    }
    dispatch({
      type: "INPUT_CHANGE",
      payload: { ...eachEntry, [e.target.name]: e.target.value },
    });
  

  };

  /*____________________________________________________________________________________________________________________________________________________ */
  useEffect(() => {
    if (response !== null) {
      dispatch({
        type: "READ",
        payload: handleTable(response[plural], getrow),
      });
    }
  }, [response, plural]);
  /*____________________________________________________________________________________________________________________________________________________ */
// useEffect(() => {
//   console.log("use Effect afher submit changes");
  
//     handleSend();
  
// }, [isSubmit]);



  const handleSubmit = () => {
     let checkErrors= handleValidation(eachEntry, validate);
if (JSON.stringify(checkErrors) !== "{}") {
  console.log(checkErrors);
  dispatch({ type: "SET_ERRORS", payload: checkErrors });
} else {
  if (isSubmit) {
    return;
  }
  dispatch({ type: "SENDING_DATA_TO_SERVER" });
  switch (isMode) {
    case "Update":
      handleUpdate();
      break;
    case "Delete":
      handleDelete();
      break;
    default:
      handleCreate();
      break;
  }
}
   
   
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleCreate = () => {
     
    const formData = new FormData();
    Object.entries(eachEntry).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(APIURL + `${plural}`, formData).then((res) => {
      if (res.data.sucess) {
        const newItem = handleTable([res.data[singular]], getrow);
        dispatch({ type: "ADDED", payload: newItem });
        dispatch({ type: "INITIAL_STATE" });
        Toast("success", pluralM, res.data.message);
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleUpdate = () => {
    const formData = new FormData();
    Object.entries(eachEntry).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(APIURL + `${plural}/${id}`, formData)
      .then((res) => {
        if (res.data.sucess) {
          const newItem = handleTable([res.data[singular]], getrow);
          dispatch({ type: "UPDATED", payload: newItem });
          Toast("success", pluralM, res.data.message);
          dispatch({ type: "INITIAL_STATE" });
        } else {
          console.log("an error" + res.data.message);
        }
      })
      .catch((error) => console.log(error));
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const handleDelete = () => {
    Axios.post(APIURL + `${plural}/${id}/delete`).then((res) => {
      if (res.data.sucess) {
        dispatch({ type: "DELETED" });
        Toast("success", pluralM, res.data.message);
        dispatch({ type: "INITIAL_STATE" });
      } else {
        console.log("an error" + res.data.message);
      }
    });
  };
 

  return {
    state,
    toggle,
    toggleAdd,
    handleInputChange,
    handleSubmit,
    isLoading,
    isError,
    errors,
  };
};

