import React from 'react'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import './dataTable.css'
const index = ({ data, onSubmit, onChange }) => {
  return (
    <table className="table table-striped table-bordered header-fixed ">
      <thead>
        <tr>
          <TableHeader columns={data.columns} />
        </tr>
      </thead>
      <tbody>
        <TableBody
          columns={data.columns}
          rows={data.rows}
          onSubmit={onSubmit}
          onChange={onChange}
          // rowUpdate={this.handleRowUpdate}
          // filterBy={this.props.filter}
        />
      </tbody>
    </table>
  );
};

export default index
