import React from "react";

const Leyenda = () => {
  return (
    <>
      <table className="table-leyenda" width="100%">
        <tbody>
          <tr>
            <td className="notas">
              <strong>Leyenda </strong>
            </td>
          </tr>
          <tr>
            <td className="notas">NOTA PROMOCIÓN: 70</td>
          </tr>
          <tr>
            <td className="notas">
              <strong>HONOR DANIEL:</strong> 94 (Todas las notas sobre 80/
              Conducta mínima 85) <strong>HONOR DAVID:</strong> 90 (Todas las
              notas sobre 80/ notas de conducta mínima 85)
            </td>
          </tr>
          <tr>
            <td className="notas">
              <strong>PRUEBA ACADÉMICA:</strong> 3 notas por debajo de 70{" "}
              <strong>PRUEBA CONDUCTUAL:</strong> 3 notas de conducta por debajo
              de 70 y/o falta gravísima
            </td>
          </tr>
          <tr>
            <td>
              <strong>NOTA</strong> Para calcular los promedios se utiliza una
              formula de promedio ponderado en base a la carga académica
              asignada a cada materia (cantidad de periodos de clase por semana,
              actualizada por pandemia)
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Leyenda;
