import React from 'react';
import { Container, Row, Col,  Card,  } from "reactstrap";

import Slider from './Slider';
// Pages Components
import WelcomeComp from "./WelcomeComp";
import Children from "./Children";


import './dashboard.scss'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';

 const Dashboard = (props) => {

        return (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs
                  title={props.t("Dashboard")}
                  breadcrumbItem={props.t("Dashboard")}
                />

                <Row>
                 
                  <Col xl="4">
                    <WelcomeComp />
                  </Col>
                 <Col xl="8" >
                   <Card>

                   <Slider/>
                   </Card>
                    <Children props={props} />
                 </Col>
                </Row>
                
              </Container>
            </div>
          </React.Fragment>
        );
        }

export default withNamespaces()(Dashboard);