import React, { useEffect, useReducer } from "react";
import { useRouteMatch } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { reducer, initialState } from "./reducer";
import { mainApi } from "../../../services/api";
import useFetch from "../../../hooks/useFetch";
import Skeleton from "react-loading-skeleton";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//i18n
import { withNamespaces } from "react-i18next";
import SecundariaBulletin from "../Bulletin/Secondaria/SecundariaBulletin";
import InicialCompetence from "../Competence/Inicial/InicialCompetence";
import PrimariaBulletin from "../Bulletin/Primaria/PrimariaBulletin";

const Report = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const match = useRouteMatch("/reports/student/:mkey");
  const { url } = match;
  const { studentData, dataReport, level, message } = state;

  const { response, isLoading, isError } = useFetch({
    api: mainApi,
    method: "get",
    url: url,
    //config: JSON.stringify({ requireAuthentication: true }),
  });
  useEffect(() => {
    if (response !== null) {
      if (response.success) {
        dispatch({ type: "SET_STUDENT", payload: response });
      } else {
        dispatch({ type: "SUCCESS_FALSE", payload: response });
      }
    }
  }, [response]);
  function printInvoice() {
    window.print();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Report")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="3" className="d-print-none">
              {isLoading ? (
                <div>
                  <Skeleton count={10} />
                </div>
              ) : (
                <Card className="overflow-hidden">
                  <div className="bg-soft-secondary">
                    <Row>
                      <Col xs="7">
                        <div
                          className="text-primary p-3"
                          style={{ minHeight: 130 }}
                        ></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="8">
                        <div className="avatar-xl text-center profile-user-wid mb-4">
                          <img
                            src={studentData?.avatar}
                            alt="avatar"
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                        <h5 className="font-size-15 ">
                          {studentData?.user_name +
                            " " +
                            studentData?.user_last_name}
                        </h5>
                        <Button
                          onClick={() => {
                            printInvoice();
                          }}
                          color="success"
                          className=" mt-4 ml-2 waves-effect waves-light"
                          disabled={isLoading}
                        >
                          <i className="fa fa-print"></i>
                        </Button>
                        {/* <p className="text-muted mb-0 text-truncate">
                              {props.t(role)}
                            </p> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xl="9">
              <Card className="p-0 m-0">
                <CardBody>
                  {isError ? (
                    <div> 404</div>
                  ) : (
                    <>
                      {isLoading ? (
                        <Skeleton count={30} />
                      ) : (
                        <>
                          {level && (
                            <>
                              {level === "Inicial" && (
                                <InicialCompetence data={dataReport} />
                              )}
                              {level === "Primaria" && (
                                <PrimariaBulletin data={dataReport[0]} literal={true}/>
                              )}
                              {level === "Secundaria" && (
                                <div className="table-responsive">
                                  <SecundariaBulletin data={dataReport[0]} />
                                </div>
                              )}
                            </>
                          )}
                          {message && (
                            <div>
                              <div className="text-center">
                                <span
                                  style={{ fontSize: "3em", color: "Tomato" }}
                                >
                                  <i className="fas fa-exclamation-triangle fa-5x" />
                                </span>
                              </div>

                              <h2 className="text-center font-weight-bold">
                                {message}
                              </h2>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(Report);
