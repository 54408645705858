import React from 'react'
import { Label, FormGroup,Row,Input,Col,} from "reactstrap";

import Select from "react-select";
const PersonalInformation = ({ props,handleInputChange,eachEntry }) => {
  const { user_name}=eachEntry;
  return (
    <>
      <h5>{props.t("Personal information")}</h5>
      <hr></hr>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="name">{props.t("Names")} *</Label>
            <Input
              id="user_name"
              name="user_name"
              autoComplete="nope"
              value={user_name.value}
              type="text"
              className="form-control"
              onChange={(e) => {
                handleInputChange(e.target.name, e.target.value);
              }}
            />
            <small className="text-danger">
              {user_name?.errors?.join(", ")}
            </small>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="name">{props.t("Last Name")}*</Label>
            <Input id="name" name="name" type="text" className="form-control" />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="phone">{props.t("Phone")}</Label>
            <Input
              id="phone"
              name="phone"
              type="phone"
              className="form-control"
            />
          </FormGroup>
          <FormGroup className="select2-container">
            <Label className="control-label">{props.t("Course")}*</Label>
            <Select
              classNamePrefix="select2-selection"
              placeholder={props.t("Please Select Course")}
              title="Country"
              // options={options}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="password">{props.t("Password")}</Label>
            <Input
              id="password"
              name="password"
              type="password"
              className="form-control"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="conditions">{props.t("Conditions")}</Label>
            <textarea className="form-control" id="conditions" rows="3" />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="control-label">{props.t("Gender")}*</Label>
            <select className="form-control select2">
              <option>{props.t("Please Select Gender")}</option>
              <option value="M">{props.t("Male")}</option>
              <option value="F">{props.t("Female")}</option>
            </select>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="rne">{props.t("RNE")}</Label>
            <Input id="rne" name="rne" type="text" className="form-control" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="address">{props.t("Address")}</Label>
            <Input
              id="address"
              name="address"
              type="address"
              className="form-control"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="price">{props.t("Username")}</Label>
            <Input
              id="username"
              name="username"
              type="username"
              className="form-control"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="confirm_password">
              {props.t("Confirm Password")}
            </Label>
            <Input
              id="confirm_password"
              name="confirm_password"
              type="confirm_password"
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>

      {/* column 1 end */}
    </>
  );
};

export default PersonalInformation;