import React from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

const index = (props) => {
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t('Student Promotion')}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Add New Students")}</CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("Fill all information below")}
                  </CardSubtitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
}
export default withNamespaces()(index);