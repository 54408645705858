export const initialInputState = {
  user_name: {
    label: "Nombres",
    value: "",
    errors: [],
    type: "text",
    rules: {
      require: true,
      maxLength: 40,
    },
  },
  user_last_name: {
    label: "Apellidos",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 70,
    },
  },
  user_sex: {
    label: "Genero",
    value: "",
    type: "select",
    rules: {
      require: true,
      maxLength: 1,
    },
  },
  user_address: {
    label: "Direccion",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 200,
    },
  },
  user_phone: {
    label: "Telefono",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 15,
    },
  },

  student_rne: {
    label: "RNE",
    value: "",
    type: "text",
    rules: {
      maxLength: 30,
    },
  },
  student_course_id: {
    label: "Curso",
    value: "",
    type: "select",
    rules: {
      require: true,
    },
  },
  user_username: {
    label: "Usuario",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 30,
    },
  },
  user_password: {
    label: "Contraseña",
    value: "",
    type: "password",
    rules: {
      require: true,
      maxLength: 70,
    },
  },
  user_confirmation_password: {
    label: "Confirmacion Contraseña",
    value: "",
    type: "password",
    rules: {
      require: true,
      maxLength: 70,
      macth: "user_password",
    },
  },
  user_birthday: {
    label: "Fecha de nacimiento",
    value: "",
    type: "date",
    rules: {
      require: true,
    },
  },
  student_nui: {
    label: "NUI",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 30,
    },
  },
  student_book: {
    label: "Libro",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 30,
    },
  },
  student_folio: {
    label: "Folio",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 30,
    },
  },
  student_certificate: {
    label: "Acta",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 30,
    },
  },
  student_year: {
    label: "Año",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 4,
    },
  },

  student_blood_group: {
    label: "Tipo de sangre",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 4,
    },
  },
  student_allergies: {
    label: "Alergias",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 150,
    },
  },
  student_drugs: {
    label: "Medicinas",
    value: "",
    type: "text",
    rules: {
      require: true,
      maxLength: 150,
    },
  },
};
