import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_TO_SOCKET,
  LOGIN_AUTO_USER,
} from "./actionTypes";

const initialState = {
    error: "",
    loading: false,
    users:[]
}

const login = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_TO_SOCKET:
        state = {
          ...state,
          users: [...state.users, action.payload],
        };
        break;
      case LOGIN_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_AUTO_USER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOGIN_SUCCESS:
        state = {
          ...state,
          loading: false,
        };
        break;
      case LOGOUT_USER:
        state = { ...state };
        break;
      case LOGOUT_USER_SUCCESS:
        state = { ...state };
        break;
      case API_ERROR:
        state = { ...state, error: action.payload, loading: false };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
}

export default login;