import React, { useEffect, useState } from "react";
import Select from "react-select";
import { mainApi } from "../../services/api";
import useFetch from "../../hooks/useFetch";
const SelectFetch = ({url,

  maxMenuHeight,
  value,
 
  placeholder,
  onChange,
}) => {
    const [options, setOptions] = useState([])
  const { response, isLoading, isError } = useFetch({
    api: mainApi,
    method: "get",
    url: url,
    //config: JSON.stringify({ requireAuthentication: true }),
  });
useEffect(() => {
  if (response !== null) {
    if (response.success) {
      setOptions(response.options)
    }
  }
}, [response]);

  return (
    <>
      <Select
        menuPortalTarget={document.body} 
        loadingMessage={() => "Cargando..."}
        isLoading={isLoading}
        isError={isError}  
        maxMenuHeight={maxMenuHeight}
        value={value}
        onChange={onChange}
        options={options}
        className="form-controll"
        classNamePrefix="select"
        placeholder={placeholder}
      />
    </>
  );
};

export default SelectFetch
