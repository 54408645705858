import { useReducer } from "react";
import { handleValidationBeforeSubmit } from "../helpers/validator";
import { mainApi } from "../services/api";
import Toast from "../helpers/toastr";
const useModuleSelect = ({ reducer, initialState }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // console.log(state);
  const { eachEntry, isDataTableLoading, errors } = state;

  /*____________________________________________________________________________________________________________________________________________________ */

  const handleInputChange = (key, value) => {
    if (errors?.[key]) {
      dispatch({ type: "RESET_AN_ENTRY_ERROR", payload: [key] });
    }
    dispatch({
      type: "INPUT_CHANGE",
      payload: {
        ...eachEntry,
        [key]: { ...eachEntry?.[key], value: value },
      },
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */
  const hangleOnchange = (e) => {
    // console.log(e)
  e.stopPropagation();
    const { name, value } = e.target;
    const newState = state.dataTable.rows.map((obj) => {
      return obj.compmarks_id === name
        ? {
            ...obj,
            1: false,
            2: false,
            3: false,
            4: false,
            [value]: true,
          }
        : obj;
    });

    dispatch({
      type: "RADIO",
      payload: newState,
    });
    const formData = new FormData();
    formData.append("compmarks_id", name);
    formData.append("compmarks_mark_id", value);
    mainApi.post(`/grades/inicial`, formData).then((response) => {
      if (response.data.success) {
       Toast("success", "Calificación", "Agregado Correctamente");
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  const getOptions = (url) => {
    dispatch({
      type: "RESET_OPTIONS",
    });
    mainApi.get(url).then((response) => {
      if (response.data.success) {
        console.log("it success");
        dispatch({
          type: "SET_DEPENDABLE_OPTIONS",
          payload: response.data,
        });
      }
    });
  };
  /*____________________________________________________________________________________________________________________________________________________ */

  const find = (url) => {
    const checkErrors = handleValidationBeforeSubmit(eachEntry);
    if (JSON.stringify(checkErrors) !== "{}") {
      console.log(checkErrors);
      dispatch({ type: "SET_ERRORS", payload: checkErrors });
    } else {
      if (isDataTableLoading) {
        return;
      }
      dispatch({ type: "LOADING_TABLE" });
      mainApi.get(url).then((response) => {
        if (response.data.success) {
          dispatch({
            type: "SET_DATA_TABLE",
            payload: response.data,
          });
        }
      });
    }
  };

  return {
    state,
    find,
    handleInputChange,
    getOptions,
    hangleOnchange,
  };
};

export default useModuleSelect
