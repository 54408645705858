import React from 'react'
import { Link } from "react-router-dom";

export const ProfileCards = ({ name, lastName, email, photo, url, props, id }) => {
  return (
    <div className=" p-2 card">
      <div className="d-flex  border rounded" style={{ minHeight: 110 }}>
        <div className="pl-3 pt-2 ">
          <h5 >{name + " " + lastName}</h5>
         
          {/* <div className="p-0 w-25">
              <img src={this.props.photo} className="img-thumbnail border-0" />
            </div> */}
          <ul
            className="m-0 float-left"
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
            }}
          >
            <li>
              {/* <i className="fab fa-facebook-square" /> */}
              <Link to={`/reports/student/${id}`}> {props.t("Grades")} </Link>
            </li>
            {/* <li>
              <i className="fab fa-twitter-square" />
              <Link to={"/conduct"}> {props.t("Conduct")} </Link>
            </li> */}
          </ul>
        </div>
        <div className="justify-content-end">
          <img
            className="img-fluid rounded-circle avatar-md "
            src={photo}
            alt="profile"
          />
        </div>
      </div>
    </div>
  );
};


export default ProfileCards;


