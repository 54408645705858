import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, LOGIN_AUTO_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods

import {
  postJwtLogin,
  postAutotLogin,
} from "../../../helpers/fakebackend_helper";



function* loginUser({ payload: { user, history } }) {
  try {
    console.log("saga login")
    const response = yield call(postJwtLogin, "/post-jwt-login", {
      email: user.email,
      password: user.password,
    });

     console.log(response)

    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    console.log(error);
    yield put(apiError(error));
  }
}


function* AutologinUser({ payload: { token, history, redirect } }) {
  try {
    const response = yield call(postAutotLogin, "parents/auto-login", {
      token: token,
    });

    localStorage.setItem("authUser", JSON.stringify(response));

    yield put(loginSuccess(response));
    console.log("redirect", redirect);
    history.push("/"+redirect);
  } catch (error) {
    console.log(error);
    history.push("/login");
    yield put(apiError(error));
  }
}


function* logoutUser({ payload: { history } }) {
    try {
         localStorage.removeItem("authUser");

    
          yield put(logoutUserSuccess());
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserAutoLogin() {
  yield takeEvery(LOGIN_AUTO_USER, AutologinUser);
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchUserAutoLogin)
    ]);
}

export default authSaga;