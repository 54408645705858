import React from "react";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useCrud } from "../../../hooks/useCrud";
import { MDBDataTableV5 } from "mdbreact";
import { reducer, initialState } from "../reducer";
import { initialInputState } from "../validate";
const WorkWith = {
  singular: "student",
  plural: "students",
  pluralM: "students",
};
const List = (props) => {
  const { state, isLoading, isError } = useCrud({
    reducer,
    initialState,
    WorkWith,
    initialInputState,
  });
  const { data, } = state;
  const { pluralM } = WorkWith;
  /*____________________________________________________________________________________________________________________________________________________ */
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t(pluralM)}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {isLoading ? (
                    <div>Loading..</div>
                  ) : (
                    <div className="table table-striped ">
                      <MDBDataTableV5
                        searchTop
                       
                        searchBottom={false}
                        data={data}
                      />
                    </div>
                  )}
                  {isError && <div>Error fechting data..</div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default withNamespaces()(List);
