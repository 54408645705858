import React from 'react'

const Attendance = ({ attendance }) => {
  return (
    <>
      <table
        className="table-attendance"
        width="100%"
        border={0}
        cellSpacing={0}
        cellPadding={0}
      >
        <tbody>
          <tr>
            <td className="condicion font-weight-bold pl-2" colSpan={16}>
              Asistencia &amp; Puntualidad
            </td>
          </tr>
          <tr className="text-center font-weight-bold">
            <td colSpan={3} />
            <td>Ago</td>
            <td>Sep</td>
            <td>Oct</td>
            <td>Nov</td>
            <td>Dic</td>
            <td>Ene</td>
            <td>Feb</td>
            <td>Mar</td>
            <td>Abr</td>
            <td>May</td>
            <td>Jun</td>
            <td colSpan={2}>Total</td>
          </tr>
          <tr>
            <td colSpan={3} className="nota pl-2 font-weight-bold">
              No. de Ausencias :
            </td>

            <td className="notas  text-center">
              {attendance?.August ? attendance.August.absence : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.September
                ? attendance.September.absence === "0"
                  ? "-"
                  : attendance.September.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.October
                ? attendance.October.absence === "0"
                  ? "-"
                  : attendance.October.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.November
                ? attendance.November.absence === "0"
                  ? "-"
                  : attendance.November.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.December
                ? attendance.December.absence === "0"
                  ? "-"
                  : attendance.December.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.January
                ? attendance.January.absence === "0"
                  ? "-"
                  : attendance.January.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.February
                ? attendance?.February?.absence === "0"
                  ? "-"
                  : attendance.February.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.March
                ? attendance.March.absence === "0"
                  ? "-"
                  : attendance.March.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.April
                ? attendance.April.absence === "0"
                  ? "-"
                  : attendance.April.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.May
                ? attendance.May.absence === "0"
                  ? "-"
                  : attendance.May.absence
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.June
                ? attendance.June.absence === "0"
                  ? "-"
                  : attendance.June.absence
                : ""}
            </td>
            <td colSpan={2} className="notas   text-center">
              {attendance?.absence
                ? attendance.absence.total === 0
                  ? "-"
                  : attendance.absence.total
                : ""}
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="nota font-weight-bold pl-2">
              No. de Tardanzas :
            </td>
            <td className="notas  text-center">
              {attendance?.August
                ? attendance.August.late === "0"
                  ? "-"
                  : attendance.August.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.September
                ? attendance.September.late === "0"
                  ? "-"
                  : attendance.September.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.October
                ? attendance.October.late === "0"
                  ? "-"
                  : attendance.October.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.November
                ? attendance.November.late === "0"
                  ? "-"
                  : attendance.November.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.December
                ? attendance.December.late === "0"
                  ? "-"
                  : attendance.December.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.January
                ? attendance.January.late === "0"
                  ? "-"
                  : attendance.January.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.February
                ? attendance.February.late === "0"
                  ? "-"
                  : attendance.February.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.March
                ? attendance.March.late === "0"
                  ? "-"
                  : attendance.March.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.April
                ? attendance.April.late === "0"
                  ? "-"
                  : attendance.April.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.May
                ? attendance.May.late === "0"
                  ? "-"
                  : attendance.May.late
                : ""}
            </td>
            <td className="notas  text-center">
              {attendance?.June
                ? attendance.June.late === "0"
                  ? "-"
                  : attendance.June.late
                : ""}
            </td>
            <td colSpan={2} className="notas  text-center">
              {attendance?.late
                ? attendance.late.total === 0
                  ? "-"
                  : attendance.late.total
                : ""}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Attendance
