import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { withNamespaces } from "react-i18next";
import {
 
  Row,
  Col,
  
 
} from "reactstrap";
import useFetch from "../../hooks/useFetch";
import ProfileCard from "../../components/Common/ProfileCard";
import { mainApi } from "../../services/api";
const Students = (props) => {
  const [students, setStudents] = useState("");
  const { response, isLoading, isError } = useFetch({
    api: mainApi,
    method: "get",
    url: "parents/son",
    //config: JSON.stringify({ requireAuthentication: true }),
  });
  useEffect(() => {
    if (response !== null) {
      if (response.success) {
        console.log(response);
        setStudents(response.students);
      }
    }
  }, [response]);

  return (
    <>
      <Row>
        {isLoading && (
          <>
            <Skeleton count={10} />
          </>
        )}
{isError?"Por Favor contacte el administrador":<> {students &&
          students.map((data) => {
            return (
              <Col xl="3" key={data.user_id}>
                <ProfileCard
                  photo={data.avatar}
                  name={data.user_name}
                  lastName={data.user_last_name}
                  props={props}
                  id={data.user_id}
                />
              </Col>
            );
          })} </>  }
        
      </Row>
    </>
  );
};
export default withNamespaces()(Students);
