import React from "react";
import { Label, FormGroup, Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import Select from "react-select";
const MedicalInformation = (props) => {
  return (
    <>
      <h5>{props.t("Medical information")}</h5>
      <hr></hr>
      <Row>
        <Col md="6">
          <FormGroup className="select2-container">
            <Label className="control-label">{props.t("Blood Group")}*</Label>
            <Select
              classNamePrefix="select2-selection"
              placeholder={props.t("Please Select Group")}
              title="blood_groug"
              // options={options}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="drugs">{props.t("Drugs")}</Label>
            <textarea className="form-control" id="drugs" rows="3" />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label htmlFor="allergies">{props.t("Allergies")}</Label>
            <textarea className="form-control" id="allergies" rows="3" />
          </FormGroup>
        </Col>
      </Row>

      {/* column 1 end */}
    </>
  );
};

export default withNamespaces()(MedicalInformation);
