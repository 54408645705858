import React, {  useEffect } from 'react';

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

const SidebarContent = (props) => {
        
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
     useEffect(() => {

        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
         initMenu();
      }, [props.location.pathname]);

  
    function  activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    const menu = {
      sideBar: [
        { parent: "Dashboard", icon: "bx bx-home-circle", url: "/#" },
        {
          parent: "Settings",
          icon: "fas fa-cog",
          url: "/#",
          child: [{ name: "Period", url: "/periods" }],
        },
        {
          parent: "Student",
          icon: "fas fa-user-graduate",
          url: "/#",
          child: [
            { name: "All Students", url: "/students/list" },
            { name: "Adminssion Form", url: "/students/add" },
            { name: "Student Promotion", url: "/students/promotion" },
          ],
        },
        {
          parent: "Teacher",
          icon: "fas fa-chalkboard-teacher",
          url: "/#",
          child: [
            { name: "All Teacher", url: "/teachers/list" },
            { name: "Add Teacher", url: "/teachers/add" },
          ],
        },
        {
          parent: "Parent",
          icon: "fas fa-user-friends",
          url: "/#",
          child: [
            { name: "All Parent", url: "/parents/list" },
            { name: "Add Parent", url: "/parents/add" },
          ],
        },
      ],
    };

          return (
            <React.Fragment>
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                  <li className="menu-title">{props.t("Menu")} </li>
                 {menu&&menu.sideBar.map((item,index)=>{
                      return (
                        <li key={index}>
                          <Link
                            to={item.url}
                            className={
                              item?.child
                                ? "has-arrow waves-effect"
                                : "waves-effect"
                            }
                          >
                      
                            <i className={item.icon}></i>
                            <span>{props.t(item.parent)}</span>
                          </Link>
                          {item?.child && (
                            <ul className="sub-menu" aria-expanded="false">
                              {item.child.map((childItem, childIndex) => {
                                return (
                                  <li key={childIndex}>
                                    <Link to={childItem.url}>
                                      {props.t(childItem.name)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );

                 })

                 }
                
               
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="fas fa-chalkboard"></i>
                      <span>{props.t("Courses")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/users/admins">{props.t("All Courses")}</Link>
                      </li>

                      <li>
                        <Link to="/users/teachers">
                          {props.t("Add Course")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="fas fa-book"></i>
                      <span>{props.t("Subject")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/users/admins">
                          {props.t("All Subjects")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/users/teachers">
                          {props.t("Add Subject")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="fab fa-buysellads"></i>
                      <span>{props.t("Academic Standars")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/users/admins">{props.t("Benchamarks")}</Link>
                      </li>
                      <li>
                        <Link to="/users/admins">{props.t("Competences")}</Link>
                      </li>
                      <li>
                        <Link to="/users/teachers">
                          {props.t("Indicators")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="fas fa-check-square"></i>
                      <span>{props.t("Grades")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/grade/import">
                          {props.t("Import Grades")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/users/admins">{props.t("Add Grade")}</Link>
                      </li>
                      <li>
                        <Link to="/grade/add/conduct">{props.t("Add Conduct")}</Link>
                      </li>
                      <li>
                        <Link to="/users/teachers">
                          {props.t("Indicators")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="fas fa-check-square"></i>
                      <span>{props.t("Anecdotal Record")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/users/admins">{props.t("Add History")}</Link>
                      </li>
                      <li>
                        <Link to="/users/admins">{props.t("Refer OD")}</Link>
                      </li>

                      <li>
                        <Link to="/users/teachers">
                          {props.t("Exhortation Letter")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/users/teachers">
                          {props.t("Removal of privileges")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="fas fa-book-reader"></i>
                      <span>{props.t("Reports")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/reports/bulletin">
                          {props.t("Bulletin")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/reports/competence">
                          {props.t("Competence")}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* <li>
                    <Link to="/solutions" className="waves-effect">
                      <i className="fas fa-wrench"></i>

                      <span>{props.t("Solutions")}</span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </React.Fragment>
          );
        }

    export default withRouter(withNamespaces()(SidebarContent));


