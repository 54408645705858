import React from 'react'

const TableHeader = ({ columns }) => {
  return (
    <React.Fragment>
      {columns &&
        columns.map((header, index, arr) => {
       const {label,} = header

          return (
            <th key={index} >
              {label}
            </th>
          );
        })}
    </React.Fragment>
  );
};

export default TableHeader
