import React,{useEffect,useState} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import io from "socket.io-client";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

const socket = io("http://localhost:3000/", {
  transports: ["websocket", "polling"],
});

const Monitor = (props) => {
    const [users, setUsers] = useState('')
    useEffect(() => {
      const userInfo = JSON.parse(localStorage.getItem("authUser"));
      if (userInfo) {
        socket.on("connect", () => {
          socket.emit("isAdmin");
        });
        
        	socket.on("aUsersHasLogged", (users) => {
            console.log(users);
            setUsers(users);
          });

      
        socket.on("aUsersHasDisconnected", (id) => {
          setUsers((users) => {
            return users.filter((user) => user.id !== id);
          });
        });
      }
    }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Monitor")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                {/* <CardTitle>{props.t("Import Grades")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("Fill all information below")}
                </CardSubtitle> */}
                {users&&users.map((user)=>
                <div key={user.id}>{user.name}</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(Monitor);
