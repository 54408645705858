import React, { useReducer } from "react";
import { withNamespaces } from "react-i18next";
import {Container,Card,CardTitle,CardBody,Button,Row,CardSubtitle,Col,Form} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PersonalInformation from './PersonalInformation';
import BirthInformation from './BirthInformation';
import MedicalInformation from './MedicalInformation';
import { reducer, initialState } from "../reducer";
import { handleValidate } from "../../../helpers/validator";

export const StudentForm = (props) => {
 const [state, dispatch] = useReducer(reducer, initialState);
 const { eachEntry } = state;
      
  const handleInputChange=(key,value)=>{
      let checkErrors = handleValidate(eachEntry?.[key], key);
console.log(checkErrors);
        dispatch({
          type: "INPUT_CHANGE",
          payload: { ...eachEntry, [key]: { ...eachEntry?.[key], value: value ,errors:checkErrors}  },
        });
        // if (JSON.stringify(checkErrors) !== "{}") {
        //     dispatch({
        //       type: "INPUT_CHANGE",
        //       payload: {
        //         ...eachEntry,
        //         [key]: { ...eachEntry?.[key], errors: checkErrors },
        //       },
        //     });
        // }
      
        

  }
//   const handleSubmit =()=>{

//   }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Admission Form")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Add New Students")}</CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("Fill all information below")}
                  </CardSubtitle>
                  <Form>
                    <Row>
                      {/* column 1 start */}
                      <Col md="6">
                        <PersonalInformation
                          handleInputChange={handleInputChange}
                          eachEntry={eachEntry}
                          props={props}
                         
                        />
                      </Col>
                      {/* column 1 end */}

                      {/* column 2 start */}
                      <Col sm="6">
                        <BirthInformation />
                        <MedicalInformation />
                      </Col>
                      {/* column 2 ends */}
                    </Row>
                    <Button
                      type="submit"
                      color="primary"
                      className="mr-1 waves-effect waves-light"
                    >
                      Save Changes
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      className="waves-effect"
                    >
                      Cancel
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};


export default withNamespaces()(StudentForm);