import React from "react";

const Signatures = ({ coordinator, teacher, director }) => {
  return (
    <div className="pt-2">
      <table width="100%">
        <tbody>
          <tr>
            <td align="center" className="border-bottom border-dark">
              <img src={teacher?.signature} alt="firma" width="140px" />
            </td>
            <td></td>
            <td align="center" className="border-bottom border-dark">
              <img src={coordinator?.signature} alt="firma" width="140px" />
            </td>
            <td></td>
            <td align="center" className="border-bottom border-dark">
              <img src={director?.signature} alt="firma" width="140px" />
            </td>
          </tr>
          <tr>
            <td className="text-center">
              Profesor Titular:
              <br />
              {teacher?.name + "  " + teacher?.last_name}
            </td>
            <td></td>
            <td className="text-center">
              Coordinadora Académica: <br />
              {coordinator?.name + "  " + coordinator?.last_name}
            </td>
            <td></td>
            <td className="text-center">
              Directora General: <br />
              Loren G. de Yabra, M.Ed.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Signatures;
