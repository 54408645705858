
import React from 'react'



export const handleIconCriteria = (mark) => {
  let icon;
  switch (mark) {
    case '1':
      icon = <span className=" fas fa-star"> </span>;
      break;
    case '2':
      icon = <span className="fas fa-check "> </span>;
      break;
    case '3':
      icon = <span className="fas fa-minus"> </span>;
      break;
    case '4':
      icon = <span className="fas fa-circle"> </span>;
      break;

    default:
      icon = <span> </span>;
      break;
  }
  return icon;
};

