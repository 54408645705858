import React, { useEffect, useState } from "react";
import io from 'socket.io-client'
import { Switch, BrowserRouter as Router,Route } from "react-router-dom";
import { connect } from "react-redux";
// Import Routes all
import { userRoutes , authRoutes } from "./routes/allRoutes";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import { loginUserToSocket } from "./store/actions";
// Import scss
import "./assets/scss/theme.scss";

const socket = io("http://localhost:3000/",{transports:["websocket","polling"]});
const setUserToSocket = (users) => {
  loginUserToSocket(users);
};
const App = (props) => {
	const [users, setUsers] = useState("")
useEffect(() => {
	 const userInfo = JSON.parse(localStorage.getItem("authUser"));
	if(userInfo){
		socket.on("connect",()=>{socket.emit("username", userInfo.username);})
	}
},[] );



   function getLayout() {
		let layoutCls = HorizontalLayout;

		switch (props.layout.layoutType) {
			case "Vertical":
				layoutCls = VerticalLayout;
				break;
			default:
				layoutCls = HorizontalLayout;
				break;
		}
		return layoutCls;
	};

		const Layout = getLayout();

			const NonAuthmiddleware = ({
				component: Component,
				layout: Layout
			}) => (
					<Route
						render={props => {
						return (
					     	   <Layout>
									<Component {...props} />
							  </Layout>
							);
						}}
					/>
				);

		  return (
		  		<React.Fragment>
				<Router>
					<Switch>
						{authRoutes.map((route, idx) => (
							<NonAuthmiddleware
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
							/>
						))}

						{userRoutes.map((route, idx) => (
							<Authmiddleware
								users={users}
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
							/>
						))}

					</Switch>
				</Router>
			</React.Fragment>
		
		  );
		}


const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, { loginUserToSocket })(App);
