

// Action Types
import {
  READ_EXHORTATION_NOTE,
  READ_EXHORTATION_NOTE_SUCCESS,
  ON_CLOSE_MODAL,
} from "./actionTypes";

export const ReadExortationNote = (url) => {
  return {
    type: READ_EXHORTATION_NOTE,
    payload: { url },
  };
};
export const ReadExortationNoteSuccess = (data) => {
  return {
    type: READ_EXHORTATION_NOTE_SUCCESS,
    payload: data,
  };
};

export const onCloseModal = () => {
  return {
    type: ON_CLOSE_MODAL,
    
  };
};