import React from "react";
import Logo_oikos from "../../../assets/images/logos/logo_oikos.jpg";
import logo_ccmb from "../../../assets/images/logos/logo_ccmb.jpg";
import logo_acsi from "../../../assets/images/logos/logo_acsi.jpg";
const Header = ({ data }) => {
  return (
    <>
      <table className="headertop" width="100%">
        <tbody>
          <tr>
            <td className="cabezal" width="16.66%">
              <img src={Logo_oikos} alt="oikos" width={100} />
            </td>
            <td className="cabezal" width="16.66%">
              <img src={logo_ccmb} alt="ccmb" width={100} />
            </td>
            <td className="cabezal" width="16.66%">
              <img src={logo_acsi} alt="acsi" width={100} />
            </td>
            <td valign="top" align="center" width="15%">
              <img src={data?.general?.avatar} alt="students" width="80"></img>
            </td>

            <td className="names" width="35%">
              {data?.user_name + " " + data?.user_last_name}
              <br />
              {data?.student_code ? data?.student_code : "0000-00000-000"}
              <br />
              {data?.course_name}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default Header;
