export const criteria=(grado)=>{



 if((97 <=grado ) && (grado <= 100)){

    return 'A+';

 } else if ((94 <=grado ) && (grado <= 96)) {
    return 'A';

 }


 else if ((90 <=grado ) && (grado <= 93)) {
    return 'A-';

 }else if ((87 <=grado ) && (grado <= 89)) {
       return 'B+';
 }
else if ((84 <=grado ) && (grado <= 86)) {
       return 'B';
 }else if ((80 <=grado ) && (grado <= 83)) {
       return 'B-';
 }
 else if ((77 <=grado ) && (grado <= 79)) {
       return 'C+';
 }
 else if ((74 <=grado ) && (grado <= 76)) {
       return 'C-';
 }else if ((70 <=grado ) && (grado <= 73)) {
       return 'C';
 }else if ((67 <=grado ) && (grado <= 69)) {
       return 'D+';
 }else if ((65 <=grado ) && (grado <= 66)) {
       return 'D';
 }
 else if ((60 <=grado ) && (grado <= 64)) {
       return 'D-';
 }else if ((0 <=grado ) && (grado <= 63)) {
       return 'F';
 }


 
}
