import React from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { reducer, initialState } from "./reducer";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
  Button,
} from "reactstrap";
import DataTable from "../../../components/Common/DataTable";
import useModuleSelect from "../../../hooks/useModuleSelect"
import ModuleSelect from "../../../components/Common/ModuleSelect";

const GradeImport = (props) => {
const {
  state,
  find,
  handleInputChange,
  getOptions,
  hangleOnchange,
} = useModuleSelect({
  reducer,
  initialState,
});

  // console.log(state);
  const {
    eachEntry,
    optionsPeriods,
    optionsSubjects,
    dataTable,
    isDataLoading,
    errors
  } = state;
  
 const { subject_id, period_id, course_id, student_id } = eachEntry;


  /*____________________________________________________________________________________________________________________________________________________ */

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Grade")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody className="pt-2">
                <CardTitle>{props.t("Add Grades")}</CardTitle>
                <CardSubtitle className="mb-3">
                  {props.t("Fill all information below")}
                </CardSubtitle>

                <ModuleSelect
                  courseSelectUrl={"courses/select/3"}
                  props={props}
                  handleInputChange={handleInputChange}
                  getOptions={getOptions}
                  eachEntry={eachEntry}
                  errors={errors}
                  actions={
                    <Button
                      onClick={() => {
                        let url = `/grades/subject/${subject_id.value.value}/period/${period_id.value.value}`;
                        if (course_id?.value.value < 6) {
                          url = `/grades/subject/${subject_id.value.value}/period/${period_id.value.value}/student/${student_id.value.value}`;
                        }
                        find(url);
                      }}
                      type="submit"
                      color="primary"
                      className=" mt-4 waves-effect waves-light"
                      disabled={isDataLoading}
                    >
                      {props.t("Find")}
                    </Button>
                  }
                  optionsSubjects={optionsSubjects}
                  optionsPeriods={optionsPeriods}
                  isDataLoading={isDataLoading}
                  student={course_id && course_id?.value.value < 6 && true}
                />
                {/* {course_id && course_id?.value.value < 6 && (
                  <Col lg="3">
                    <FormGroup>
                      <Label>{props.t("Courses")}</Label>
                      <SelectFetch
                        url={`/courses/${course_id.value.value}/select/students`}
                        // minMenuHeight={600}
                        value={student_id.value}
                        onChange={(selected) => {
                          handleInputChange("student_id", selected);
                          // getOptions(`courses/selects/${selected.value}`);
                        }}
                        placeholder={props.t("Select a Student")}
                      />
                      <small className="text-danger">
                        {errors?.student_id}
                      </small>
                    </FormGroup>
                  </Col>
                )} */}

                {isDataLoading && (
                  <div>
                    Sincronizando los datos
                    <Spinner size="sm" type="grow" color="info" />
                  </div>
                )}

                {dataTable && !isDataLoading && (
                  <>
                    <div className="table-responsive">
                      <DataTable data={dataTable} onChange={hangleOnchange} />
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withNamespaces()(GradeImport);
