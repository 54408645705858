import { initialInputState } from "./validate";

export const initialState = {
  eachEntry: initialInputState,

  error: {},
  studentData: null,
  dataReport: null,
  isLoading: false,
  level: "",
  literal: false,
  message: "",
};

export const reducer = (state, action) => {
  if (action.type === "INPUT_CHANGE") {
    return { ...state, eachEntry: action.payload };
  }

  if (action.type === "LOADING") {
    return { ...state, isLoading: true, literal: true };
  }
  if (action.type === "RESET_OPTIONS") {
    return {
      ...state,
      eachEntry: {
        ...state.eachEntry,
        period_id: { ...state.eachEntry?.period_id, value: "", errors: "" },
        subject_id: { ...state.eachEntry?.subject_id, value: "", errors: "" },
      },
      optionsSubjects: [],
      optionsPeriods: [],
      dataTable: null,
    };
  }

  if (action.type === "RESET_AN_ENTRY_ERROR") {
    const { [action.payload]: value, ...newErrors } = state.errors;
    return {
      ...state,
      errors: newErrors,
    };
  }
  if (action.type === "SET_STUDENT") {
    return {
      ...state,
      studentData: action.payload.student,
      dataReport: action.payload.report,
      level: action.payload.level,
    };
  }
  if (action.type === "SUCCESS_FALSE") {
    return {
      ...state,
      message: action.payload.message,
    };
  }
};
