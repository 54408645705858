import React from "react";
import { Label, FormGroup, Row, Input, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
const BirthInformation = (props) => {
  return (
    <>
      <h5>{props.t("Birth certificate information")}</h5>
      <hr></hr>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="control-label">{props.t("Date Of Birth")}*</Label>
            <DatePicker
              //   selected={startDate}
              //   onChange={handleChange}
              className="form-control"
              placeholderText={props.t("dd/mm/yyyy")}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="book">{props.t("Book")} *</Label>
            <Input id="book" name="book" type="text" className="form-control" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="acta">{props.t("Acta")} *</Label>
            <Input id="acta" name="acta" type="text" className="form-control" />
          </FormGroup>
        </Col>
        <Col md="6">
          {" "}
          <FormGroup>
            <Label htmlFor="nui">{props.t("NUI")}</Label>
            <Input id="nui" name="nui" type="text" className="form-control" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="folio">{props.t("Folio")} *</Label>
            <Input
              id="folio"
              name="folio"
              type="text"
              className="form-control"
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="folio">{props.t("Year")} *</Label>
            <Input id="year" name="year" type="text" className="form-control" />
          </FormGroup>
        </Col>
      </Row>

      {/* column 1 end */}
    </>
  );
};

export default withNamespaces()(BirthInformation);
