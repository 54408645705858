import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReadExortationNote,onCloseModal } from '../../store/BehaviorTrack/ExhortationNote/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import ExhortationNoteView from "./ExhortationNoteView";

const ExhortationNote = (props) => {
  const { t, isModalView,data } = props;
  const match = useRouteMatch("/exhortation-notes/:mkey");
  const { url } = match;


  useEffect(() => {
    props.ReadExortationNote(url); // Fire ReadExortationNote action when the component mounts
  }, []);
 
const handleCloseView = () => {
  props.onCloseModal();
 props.history.push('/dashboard');


}
console.log(isModalView);

  return (
    <div className="page-content">


      <ExhortationNoteView
        isModalView={isModalView}
        handleCloseView={handleCloseView}
        t={t}
        currentExortationNote={data}
      
      
      />
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { error, data, isModalView } = state.ExhortationNote;
  return { error, data, isModalView};
};



export default withRouter(
  connect(mapStatetoProps, { ReadExortationNote, onCloseModal })(
    withNamespaces()(ExhortationNote)
  )
);

