import { initialInputState } from "./validate";

export const initialState = {
  eachEntry: initialInputState,
  optionsCourses: [],
  optionsSubjects: [],
  optionsPeriods: [],
  error: {},
  studentData: null,
  isLoading: false,
  typeOfBulletin: "",
  literal: true,
 
};

export const reducer = (state, action) => {
 

  if (action.type === "INPUT_CHANGE") {
    return { ...state, eachEntry: action.payload };
  }
  if (action.type === "READ_SELECT_SUCCESS") {
    return { ...state, optionsCourses: action.payload };
  }
  if (action.type === "LOADING") {
    return { ...state, isLoading: true, literal: true };
  }
  if (action.type === "RESET_OPTIONS") {
    return {
      ...state,
      eachEntry: {
        ...state.eachEntry,
        period_id: { ...state.eachEntry?.period_id, value: "", errors: "" },
        subject_id: { ...state.eachEntry?.subject_id, value: "", errors: "" },
      },
      optionsSubjects: [],
      optionsPeriods: [],
      dataTable: null,
    };
  }

  if (action.type === "SET_DEPENDABLE_OPTIONS") {
    return {
      ...state,
      optionsSubjects: action.payload.optionsSubjects,
      optionsPeriods: action.payload.optionsPeriods,
    };
  }

  if (action.type === "SET_ERRORS") {
    return {
      ...state,
      errors: action.payload,
    };
  }
  if (action.type === "RESET_AN_ENTRY_ERROR") {
    const { [action.payload]: value, ...newErrors } = state.errors;
    return {
      ...state,
      errors: newErrors,
    };
  }
  if (action.type === "LOAD_ALL_STUDENT") {
    return {
      ...state,
      studentData: action.payload.students,
      typeOfBulletin: action.payload.level,
      oldStudentData: action.payload.students,
      isLoading: false,
     
    };
  }
if (action.type === "APPLY_CRITERIA") {
  return {
    ...state,
    studentData: action.payload,
   
   
  };
}
if (action.type === "NUMERIC_ENABLED") {
  return {
    ...state,
    literal: false,
  };
}

if (action.type === "CHECKBOX_CHANGE") {
  return {
    ...state,
    literal: action.payload,
  };
}

  if (action.type === "INITIAL_STATE") {
    return {
      ...state,
      eachEntry: initialInputState,
      modalColor: "success",
      id: null,
      errors: {},
    };
  }
  if (action.type === "LITERAL_ENABLED") {
    return{ ...state,
    literal:true,
    
  }
  }
};
