import toastr from "toastr";
import "toastr/build/toastr.min.css";
function objToString(obj) {
  var str = "";
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str +=  obj[p] + "\n";
    }
  }
  return str;
}
const Toast = (toastType, title, message) => {
    if (typeof message === "object" && message !== null) {
       message = objToString(message); 

    }
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 3000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (toastType === "info") toastr.info(message, title);
  else if (toastType === "warning") toastr.warning(message, title);
  else if (toastType === "error") toastr.error(message, title);
  else toastr.success(message, title);
};

export default Toast;
