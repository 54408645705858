export const LOGIN_TO_SOCKET = "LOGIN_TO_SOCKET";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const LOGIN_AUTO_USER = "LOGIN_AUTO_USER";

