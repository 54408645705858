import React from "react";
import {
  // UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
//import { Link } from "react-router-dom";

export const handleTable = (data, tableOptions) => {
  let tableData;
  tableData = {
    rows: [
      ...data.map((row, order) => ({
        ...row,

        options: (
          <>
            {/* <Link
              to="#"
              className="mr-3 text-primary"
              onClick={() => {
                tableOptions(row, "update", order);
              }}
            >
              <i
                className="mdi mdi-pencil font-size-18"
                id={"edittooltip" + order}
              ></i>
              <UncontrolledTooltip
                placement="top"
                target={"edittooltip" + order}
              >
                Editar
              </UncontrolledTooltip>
            </Link>

            <Link
              to="#"
              className="text-danger"
              onClick={() => {
                tableOptions(row, "delete", order);
              }}
            >
              <i
                className="mdi mdi-close font-size-18 mr-3"
                id={"deletetooltip" + order}
              ></i>
              <UncontrolledTooltip
                placement="top"
                target={"deletetooltip" + order}
              >
                Eliminar
              </UncontrolledTooltip>
            </Link> */}
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="#">
                  <i className="fas fa-pencil-alt text-success mr-1" />
                  Edit
                </DropdownItem>
                <DropdownItem href="#">
                  <i className="fas fa-trash-alt text-danger mr-1" />
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
      })),
    ],
  };

  return tableData;
};
