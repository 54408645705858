import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { READ_EXHORTATION_NOTE } from "./actionTypes";
import { ReadExortationNoteSuccess } from "./actions";

//Include Both Helper File with needed methods

import { apiClient } from "../../../api/axios";
import { exhortationNotesEndpoints } from "../../../api/endpoints";

function* fetchExhortationNoteRid({ payload: { url } }) {
  try {

    const response = yield call(apiClient.get, exhortationNotesEndpoints.showByRid(url));
    yield put(ReadExortationNoteSuccess(response.data));
  } catch (error) {
    //yield put(profileError(error));
  }
}
export function* watchExhortation() {
  yield takeEvery(READ_EXHORTATION_NOTE, fetchExhortationNoteRid);
}

function* ExhortationNoteSaga() {
  yield all([fork(watchExhortation)]);
}

export default ExhortationNoteSaga;
