import React from 'react'
import { useLocation } from "react-router-dom";
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { autoLoginUser, apiError } from '../../store/auth/login/actions';


const AutoLogin = (props) => {

    const location = useLocation();

const token = new URLSearchParams(location.search).get("token");
const redirect=new URLSearchParams(location.search).get("redirect");

    React.useEffect(() => {
        if (token) {
            console.log("token", token)
            props.autoLoginUser(token, props.history , redirect)
          
        }
    } , [token,redirect])
       




  return (
    <div> "Cargando..."</div>
  )
}






const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(
  connect(mapStatetoProps, { autoLoginUser, apiError })(AutoLogin)
);
