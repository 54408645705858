import {
  READ_EXHORTATION_NOTE,
  READ_EXHORTATION_NOTE_SUCCESS,
  ON_CLOSE_MODAL,
} from "./actionTypes";

const initialState = {
  error: "",
  isLoading: false,
    data: null,
    isModalView: false,
  
};

const ExhortationNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_EXHORTATION_NOTE:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case READ_EXHORTATION_NOTE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        data: action.payload,
        isModalView: true,
      };
      break;
    case ON_CLOSE_MODAL:
      state = {
        ...state,
        isModalView: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ExhortationNoteReducer;
// http://localhost:3001/auto-login/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMjg5IiwidXNlcm5hbWUiOiJnaWFzYW50aWFnbyIsInJvbGVfaWQiOjUsImlhdCI6MTcwNzMxNTY0NSwiZXhwIjoxNzA3NzQ3NjQ1fQ.MkGkjv1ebzVb-gpseQEVN93ZzNZpFqd7AzGSQjDU4ZxMXSdYgGP22KKmx8x8XL4avRHdQGfyjqESxHS0kMxfN-PmgBTiD705A0RShdin6txnxGUya06A5dihUzicskAsk-YYcxXEeBuun_1lynvBc4HahbuB9y4CWhVvRR5RmWw&redirect=exhortation-notes/2fa1075cdcc27e45ec00e66780b36ee7038faace1789946a253ed84b246e6258