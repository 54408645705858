import React from "react";

const LeyendPrimary = () => {
  return (
    <>
      <table
        className="leyenda"
        width="100%"
        border={0}
        cellSpacing={0}
        cellPadding={0}
      >
        <tbody>
          <tr>
            <td className="tleyenda  font-weight-bold text-center" colSpan={16}>
              LEYENDA
            </td>
          </tr>
          <tr>
            <td className="notasleyenda text-center">
              <strong>A+</strong>
              <br />
              97-100
            </td>
            <td className="notasleyenda text-center">
              <strong>A</strong>
              <br />
              94-96
            </td>
            <td className="notasleyenda text-center">
              <strong>A-</strong>
              <br />
              90-93
            </td>
            <td className="honor" rowSpan={5} colSpan={13}>
              <strong>HONOR DANIEL:</strong> A (94) Todas las notas sobre 80
              (B-) y nota de conducta mínima 85 (B)
              <br />
              <br /> <strong>HONOR DAVID:</strong> A- (90) Todas las notas sobre
              80 (B-) y nota de conducta mínima 85 (B)
              <br />
              <br />
              <strong>PRUEBA ACADÉMICA:</strong> Tres (3) notas en D.
              <br />
              <br />
              <strong>PRUEBA CONDUCTUAL:</strong> Tres (3) notas en D y/o falta
              gravísima.
              <br />
              <br />
              <strong>NOTA PROMOCIÓN:</strong> D (65)
            </td>
          </tr>
          <tr>
            <td className="notasleyenda text-center">
              <strong>B+</strong>
              <br />
              87-89
            </td>
            <td className="notasleyenda text-center">
              <strong>B</strong>
              <br />
              84-86
            </td>
            <td className="notasleyenda text-center">
              <strong>B-</strong>
              <br />
              80-83
            </td>
          </tr>
          <tr>
            <td className="notasleyenda text-center">
              <strong>C+</strong>
              <br />
              77-79
            </td>
            <td className="notasleyenda text-center">
              <strong>C</strong>
              <br />
              74-76
            </td>
            <td className="notasleyenda text-center">
              <strong>C-</strong>
              <br />
              70-73
            </td>
          </tr>
          <tr>
            <td className="notasleyenda text-center">
              <strong>D+</strong>
              <br />
              67-69
            </td>
            <td className="notasleyenda text-center">
              <strong>D</strong>
              <br />
              65-66
            </td>
            <td className="notasleyenda text-center">
              <strong>D-</strong>
              <br />
              60-64
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="notasleyenda text-center">
              <strong>F</strong>
              <br />
              0-59
            </td>
          </tr>
          <tr>
            <td className="nota text-center font-weight-bold" colSpan={16}>
              <strong>Excelencia fundamentada en la Roca</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default LeyendPrimary;
