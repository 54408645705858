export const handleValidation = (eachEntry, validate) => {
  let error = [];
  let objErrors={};

  for (var key in eachEntry) {
    if (validate[key]) {
      if (validate[key].rules?.require) {
        if (!eachEntry[key].trim()) {
          error.push("This is a required field");
          objErrors[key] = ["This is a required field"];
        }
      }
      if (validate[key].rules?.maxLength) {
        if (eachEntry[key].length > validate[key].rules.maxLength) {
          error.push(
            
          );
           objErrors[
             key
           ] = [`Please enter less than ${validate[key].rules.maxLength} characters !!!`];
        }
      }
  //  if (error.length>0) {
     
  //            objErrors[key] = error;
       
  //  }
    
    }
  }
  return objErrors;
};
export const handleValidate = (entry, key) => {
 
  let error = [];

  if (entry?.rules?.require) {
    if (entry.value) {
     
      error.push("This is a required field");
    }
  }
  if (entry?.rules?.maxLength) {
    if (entry.value.length > entry.rules.maxLength) {
        error.push(
          `Please enter less than ${entry.rules.maxLength} characters !!!`
        );
    
    }
  }

  return error;
};

export const handleValidationBeforeSubmit = (eachEntry) => {

  let objErrors = {};

  for (var key in eachEntry) {
   
    if (eachEntry[key]) {
      if (eachEntry[key].rules?.require) {
        
        if (!eachEntry[key].value) {
          objErrors[key] = "This is a required field";
        }
      }
      if (eachEntry[key].rules?.maxLength) {
        if (eachEntry[key].value.length > eachEntry[key].rules.maxLength) {
          objErrors[key] = `Please enter less than ${eachEntry[key].rules.maxLength} characters !!!`  
        }
      }
    }
  }
  return objErrors;
};
