import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
//import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";



const WelcomeComp = (props) => {
     const [name, setName] = useState("");
     const [avatar, setAvatar] = useState("");
     const [role, setRole] = useState('')
    useEffect(() => {
      if (localStorage.getItem("authUser")) {
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
          const obj = JSON.parse(localStorage.getItem("authUser"));
          setName(obj.name);
        } else if (
          process.env.REACT_APP_DEFAULTAUTH === "fake" ||
          process.env.REACT_APP_DEFAULTAUTH === "jwt"
        ) {
          const obj = JSON.parse(localStorage.getItem("authUser"));
          setName(obj.name);
          setAvatar(obj.avatar);
          setRole(obj.role)

        }
      }
    }, [props.success]);
          return (
            <React.Fragment>
              <Card className="overflow-hidden">
                <div className="bg-soft-secondary">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">
                          {props.t("Welcome Back!")}
                        </h5>
                        <p>{props.t("Dashboard")}</p>
                      </div>
                    </Col>
                    {/* <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="8">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={avatar}
                          alt="avatar"
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">{name}</h5>
                      <p className="text-muted mb-0 text-truncate">
                        {props.t(role)}
                      </p>
                    </Col>

                    {/* <Col sm="4">
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <h5 className="font-size-15">125</h5>
                            <p className="text-muted mb-0">Projects</p>
                          </Col>
                          <Col xs="6">
                            <h5 className="font-size-15">$1245</h5>
                            <p className="text-muted mb-0">Revenue</p>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <Link
                            to=""
                            className="btn btn-primary waves-effect waves-light btn-sm"
                          >
                            View Profile{" "}
                            <i className="mdi mdi-arrow-right ml-1"></i>
                          </Link>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </React.Fragment>
          );
        }
export default  withNamespaces()(WelcomeComp);
 